.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $secondary-color;
  color: $secondary-text-color;

  @include media-breakpoint-down(md) {
    visibility: hidden;
  }
}
