.header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $primary-layout-color;
  z-index: 100;

  @include media-breakpoint-up(md) {
    justify-content: space-around;
  }

  &-brand {
    display: flex;
    align-items: center;
    margin: 0px 16px;
    @include media-breakpoint-up(md) {
      margin: 12px 0px;
    }
  }

  &-logo {
    width: 85px;
    display: none;
    cursor: pointer;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &-menu {
    padding: 12px;
    @include media-breakpoint-up(md) {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
  }

  &-item {
    color: $primary-text-color;
    list-style-type: none;
    text-decoration: none;
    margin: 0px 4px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    margin: 0px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:hover {
      color: $primary-color;
      cursor: pointer;
    }
  }

  &-option {
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .option--active {
    color: $primary-color;
  }

  &-user {
    @extend .header-item;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-line {
    @extend .header-item;
    border-right: 2px solid $primary-color-dark;
    padding: 6px 0px !important;
    height: 2rem;
    margin: auto 0px;
    list-style: none;
    &:hover {
      cursor: none;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &-close {
    @extend .header-item;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .user-icon {
    width: 20px;
    height: 20px;
    //padding: 3px;
    margin: 0px 6px;
    mix-blend-mode: color-dodge;
  }

  &-decoration {
    position: absolute;
    width: 100%;
    top: -30px;
    left: 0;
    right: 0;
    z-index: 0;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.header-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #c5c5c55e;
  z-index: 500;

  &--open {
    display: block;
  }

  &--close {
    display: none;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }

  .header-overlay {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $secondary-color;
    align-items: center;
    color: $secondary-text-color;
    z-index: 600;

    p {
      cursor: pointer;
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 12px;
      font-size: 16px;
    }
  }

  .header-drop {
    cursor: pointer;
    position: absolute;
    top: 0%;
    left: 75%;
    padding: 12px;
  }
}
