.profile {
  &--close {
    display: none;
  }

  &--emptyField {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &-empty {
    text-align: center;
    margin: 90px auto;
    font-size: 30px;
    color: $tertiary-text-color;

    label {
    }
  }

  &-list {
    color: $tertiary-text-color;
    position: relative;
    @include media-breakpoint-up(md) {
      background-color: $white-pure;
      border-radius: 0px 0px 9px 9px;
    }
  }

  &-header {
    width: 100%;
    display: none;
    grid-template-columns: 50% 50%;

    p {
      margin: 14px 7px;
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      display: grid;
      color: $secondary-text-color;
      border-radius: 9px 9px 0px 0px;
      background-color: $secondary-color;

      &--4 {
        grid-template-columns: 20% 20% 30% 30%;
        background-color: $secondary-color;
      }

      &--5 {
        grid-template-columns: 15% 20% 30% 20% 15%;
      }

      &--6 {
        grid-template-columns: 15% 15% 22% 15% 20% 13%;
      }
    }
  }

  &-item {
    display: grid;
    grid-template-columns: 1fr 25px;
    background-color: $white;
    border-radius: 9px;
    padding: 12px;
    margin: 12px 0px;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin: 0px;
      padding: 6px 4px;
    }

    &:hover {
      @include media-breakpoint-up(md) {
        background-color: $gray-middle-light;
        border-radius: 0px;
        cursor: pointer;
      }
    }
  }

  &-data {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    p {
      text-align: start;
      margin: 6px 10px;
    }

    @include media-breakpoint-up(md) {
      p {
        text-align: center;
      }

      &--4 {
        display: grid;
        grid-template-columns: 20% 20% 30% 30%;
      }

      &--5 {
        display: grid;
        grid-template-columns: 15% 20% 30% 20% 15%;
      }

      &--6 {
        display: grid;
        grid-template-columns: 15% 15% 22% 15% 20% 13%;
      }
    }
  }

  &-arrow {
    text-align: center;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &-img {
      text-align: center;
      &--open {
        transform: rotate(180deg);
        transition-duration: 0.5s;
      }
      &--close {
        transform: rotate(0deg);
        transition-duration: 0.5s;
      }
    }
  }

  &-title {
    font-weight: 700;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &-value {
    @include media-breakpoint-up(md) {
      display: block;
      text-align: center;
    }

    .bttn {
      margin: 0px;
      padding: 0px;
      font-weight: normal;
      @include media-breakpoint-down(md) {
        &-content {
          justify-content: start;
        }
      }
    }
  }

  &-phone {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 80% 20%;
    }
    .bttn {
      margin: 0px;
      padding: 0px;
      font-weight: normal;
      @include media-breakpoint-down(md) {
        &-content {
          justify-content: start;
        }
      }
    }
  }

  &-wa {
    width: 10px;
  }
}

.sync-catalogs {
  border-radius: 50%;
  width: 25px;

  &:hover {
    background-color: $secondary-bg-color;
    cursor: pointer;
  }

  &--charging {
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    animation: spin 0.7s infinite linear;

    &:hover {
      background-color: transparent;
    }
  }
}

.form {
  @extend .card;
  @extend .card--white;
  box-shadow: none;
  //z-index: 1000;
  position: relative;

  &-profile {
    margin: 1.5em auto;
    padding: 15px;
  }

  &-contact {
    margin: 1.5em auto;
    padding: 15px;

    @include media-breakpoint-down(md) {
      margin: 0px auto 70px auto;
      padding: 15px;
    }

    .contact-control {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;
      align-items: center;
    }
  }

  &-med {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 14px;
    align-items: center;
    &:hover {
      background-color: $gray-middle-light;
      border-radius: 0px;
      cursor: pointer;
    }

    /*@include media-breakpoint-down(md) {
      margin: 0px auto 70px auto;
      padding: 15px;
    }

    .contact-control {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;
      align-items: center;
    }*/
  }

  &-data {
    width: 96%;
    display: block;
    margin: auto;
    position: relative;

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: max-content;
      gap: 16px;
      align-items: flex-start;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-image: url("../static/ic_arrow_red_down.svg");
      background-position: right center;
      //background-color: $disable-color;
      background-repeat: no-repeat;
      background-origin: content-box;
    }

    .selection-gender {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin: 12px 0px;
      * {
        width: 30%;
      }
    }

    .field {
      &--hide {
        display: none;
      }
    }

    .line-horizontal {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: auto;
      grid-row-end: auto;
      height: 1px;
      width: 100%;
    }

    .blank-list {
      height: 30px;
      padding: 50px;
      margin: auto;
    }

    .layout-bottom {
      //position: relative;
    }
  }

  .layout-top {
    @extend .card;
    @extend .card--white;
    box-shadow: none;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 35%);
    width: 100%;
    height: 100%;
  }

  &-error {
    color: $red;
    font-size: small;
    margin-top: 0;
    margin-left: 4px;
  }

  &-report {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px 36px;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &-criteria {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px 36px;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--margin {
    margin: 40px 0px 10px 0px;
  }

  &-field {
    width: 100%;
    text-align: start;
    height: -webkit-max-content;

    label {
      width: 100%;
    }

    &--hide {
      display: none;
    }

    &-artropatia {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &-check {
    height: max-content;
    margin-top: 30px;
  }

  &-img-upload {
    position: relative;
    width: 50px;
    margin-bottom: 10px;

    &-stack {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    }

    .picture {
      position: relative;
      max-height: 50px;
      width: 50px;

      &:hover + .close {
        display: block;
      }

      &-content {
        width: 100%;
        max-height: 60px;
      }
    }

    .close {
      width: 25px;
      height: 25px;
      position: absolute;
      background-color: $red;
      top: -14px;
      left: 38px;
      padding: 3px;
      display: none;
      &:hover {
        display: block;
      }
    }

    p {
      margin-top: 0px;
      font-size: 10px;
      font-weight: 200;
    }
  }

  &-listin {
    grid-column: span 2;
    width: 100%;
    text-align: start;
    height: -webkit-fill-available;

    label {
      width: 100%;
    }

    .badges {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
    }

    .badge {
      flex-basis: 33.33%;
      position: relative;

      .entry {
        &:hover + .close {
          display: block;
        }

        &-content {
          width: 100%;
          max-height: 60px;
        }
      }

      .close {
        width: 25px;
        height: 25px;
        position: absolute;
        background-color: $red;
        top: 26%;
        left: 79%;
        padding: 3px;
        display: none;
        &:hover {
          display: block;
        }
        @include media-breakpoint-down(sm) {
          display: block;
          left: 58%;
        }
      }
    }

    .setter {
      display: flex;
      flex-direction: row;

      &-input {
        width: 20%;
        min-width: 20%;
        max-width: 20%;
        margin-right: 16px;
      }
    }
  }
  /*.btn-aviso {
    margin: 10px 0;
    text-align: center;

    &-download {
      width: 100%;
      @include media-breakpoint-up(md) {
        margin: 0px 12px;
        width: 70%;
      }
    }
  }*/
}

.submit {
  margin: auto;
  padding: 9px;
  width: 100%;
  display: grid;
  gap: 12px;

  &-preregistro {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &-subregistro {
    grid-template-columns: 1fr 1fr;
  }

  &-paciente {
    grid-template-columns: 1fr;
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    gap: 2px 0px;

    body {
      background-color: $gray-light;
    }

    .bttn {
      padding: 0px !important;
    }
  }
}

.sugerencia {
  padding: 5px;
}

.react-autosuggest__suggestions-container--open {
  background-color: #fff;
  z-index: 2;
  max-height: 100px;
  overflow: auto;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;

  cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
