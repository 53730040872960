$font-primary: "Roboto", sans-serif;
$brown: #592f30;
$brown-bright: #795253;
$brown-light: rgb(209, 189, 166);
$brown-dark: #45292a;
$blue: #1c92d9;
$blue-bright: #60aedf;
$blue-light: #d6dff0;
$blue-dark: #19618c;
$bluely: rgb(120, 197, 255);
$bluely-bright: rgb(75, 165, 249);
$purple: #7367ff;
$purple-bright: #3e2df7;
$purple-dark: #35316a;
$purple-light: #ddd6f0;
$purply: #faf8ff;
$purply-bright: #e1dbf1;
$black: #383232;
$black-light: #635854;
$coffe: #735448;
$coffe-bright: #bfa89f;
$black-dark: #453a45;
$gray-dark: #3d3d3d;
$gray: #707070;
$gray-bright: #716a63;
$gray-middle: #bdbdbd;
$gray-light: #f0f0f0;
$gray-middle-light: #e6e4e4;
$gray-middle-dark: #030303;
$red: #d83236;
$red-bright: #df7678;
$red-dark: #a62628;
$redly: rgb(242 191 191 / 63%);
$redly-bright: rgb(242 191 191);
$orange: #ea8f2d;
$orange-bright: #eeb375;
$yellow: #d4ac44;
$yellow-bright: #f3c44f;
$white: #fefdfd;
$white-pure: #ffffff;
$white-bright: #f5f6f9;
$green: #008c2c;
$green-bright: #3fa611;
$night: #0f0f21;
$pink: #ff5457;
$pink-bright: #ff9496;

$primary-color: $blue;
$primary-color-bright: $blue-bright;
$primary-color-dark: $blue-dark;
$primary-light-color: $bluely;
$primary-light-color-bright: $bluely-bright;
$secondary-color: $orange;
$secondary-color-bright: $orange-bright;
$tertiary-color: $purple;
$tertiary-color-bright: $purple-bright;
$accent-color: $yellow;
$accent-color-bright: $yellow-bright;
$primary-bg-color: $gray-light;
$secondary-bg-color: $pink-bright;
$tertiary-bg-color: $blue-light;
$accent-bg-color: $purply;
$primary-layout-color: $blue-dark;
$disable-color: $gray-middle;
$neutro-color: $gray;
$primary-text-color: $white;
$secondary-text-color: $white;
$tertiary-text-color: $black-dark;
$accent-text-color: $black-light;

@import "bootstrap/scss/functions";
//@import "bootstrap/scss/variables";
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

@import "bootstrap/scss/mixins/breakpoints";

@font-face {
  font-family: "Roboto";
  font-weight: 100;
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-Thin.ttf) format("opentype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: italic;
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-ThinItalic.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: italic;
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-BoldItalic.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-Black.ttf) format("opentype");
}
