html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-primary;
  font-weight: 400;
  font-size: 14px;
  color: $primary-text-color;
}

* {
  box-sizing: border-box;
}

/* Link */
a {
  text-align: right;
  color: inherit;
  transition: all 0.25s ease-out;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

/* Input */
.inpt {
  border-radius: 5px;
  border: 1px solid $primary-bg-color;
  padding: 8px 12px;
  margin: 16px 0;
  width: 100%;
  box-sizing: border-box;
  color: $accent-text-color;
  height: 42px;
  font-size: 13px;
  caret-color: $primary-color;

  &:focus {
    outline: none !important;
    box-shadow: 0 0 2px $primary-color;
  }

  &:disabled.btn {
    color: green;
    background-color: #000;
  }
}

/* Button */
.bttn {
  padding: 7px 20px;
  margin: 8px 0px;
  border: none;
  font-weight: 700;
  width: 100%;
  min-height: 42px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;

  &:focus-visible {
    outline: none !important;
    box-shadow: 0 0 2px $primary-color;
  }

  &-loading {
    width: 20px;
    height: 20px;
    margin: 4px;
  }

  &-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &--white {
    background-color: $white;
    color: $black;
    &:hover {
      background-color: $white-bright;
    }
    &:active {
      background-color: $white;
    }
    &:focus-visible {
      background-color: $white-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $black-light;
      cursor: progress;
      background-color: $white-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--transparent {
    background-color: transparent;
    color: $black-light;
    &:hover {
      background-color: $white-pure;
    }
    &:active {
      background-color: transparent;
    }
    &:focus-visible {
      background-color: $white-pure;
    }
    &--disable {
      color: $white-pure;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $black-light;
      cursor: progress;
      background-color: transparent;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--primary-color {
    background-color: $primary-color;
    color: $white;

    &:hover {
      background-color: $primary-color-bright;
    }
    &:active {
      background-color: $primary-color;
    }
    &:focus-visible {
      background-color: $primary-color-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $primary-color-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--primary-light-color {
    background-color: $primary-light-color;
    color: $white;

    &:hover {
      background-color: $primary-light-color-bright;
    }
    &:active {
      background-color: $primary-light-color;
    }
    &:focus-visible {
      background-color: $primary-light-color-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $primary-light-color-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--secondary-color {
    background-color: $secondary-color;
    color: $white;

    &:hover {
      background-color: $secondary-color-bright;
    }
    &:active {
      background-color: $secondary-color;
    }
    &:focus-visible {
      background-color: $secondary-color-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $secondary-color-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--tertiary-color {
    background-color: $tertiary-color;
    color: $white;

    &:hover {
      background-color: $tertiary-color-bright;
    }
    &:active {
      background-color: $tertiary-color;
    }
    &:focus-visible {
      background-color: $tertiary-color-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $tertiary-color-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--accent-color {
    background-color: $accent-color;
    color: $white;

    &:hover {
      background-color: $accent-color-bright;
    }
    &:active {
      background-color: $accent-color;
    }
    &:focus-visible {
      background-color: $accent-color-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $accent-color-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--red {
    background-color: $red;
    color: $white;

    &:hover {
      background-color: $red-bright;
    }
    &:active {
      background-color: $red;
    }
    &:focus-visible {
      background-color: $red-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
      /*&::before {
        content: url("../static/Spin-Blanco.gif");
      }*/
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $red-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--yellow {
    background-color: $yellow;
    color: $white;
    &:hover {
      background-color: $yellow-bright;
    }
    &:active {
      background-color: $yellow;
    }
    &:focus-visible {
      background-color: $yellow-bright;
    }
    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $yellow-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--orange {
    background-color: $orange;
    color: $white;
    &:hover {
      background-color: $orange-bright;
    }
    &:active {
      background-color: $orange;
    }
    &:focus-visible {
      background-color: $orange-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $orange-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--blue {
    background-color: $primary-color;
    color: $white;
    &:hover {
      background-color: $primary-color-bright;
    }
    &:active {
      background-color: $primary-color;
    }
    &:focus-visible {
      background-color: $primary-color-bright;
    }
    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $primary-color-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--green {
    background-color: $green;
    color: $white;
    &:hover {
      background-color: $green-bright;
    }
    &:active {
      background-color: $green;
    }
    &:focus-visible {
      background-color: $green-bright;
    }
    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $green-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--gray {
    background-color: $gray;
    color: $white;
    &:hover {
      background-color: $gray-bright;
    }
    &:active {
      background-color: $gray;
    }
    &:focus-visible {
      background-color: $gray-bright;
    }
    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $gray-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--coffe {
    background-color: $coffe;
    color: $white;
    &:hover {
      background-color: $coffe-bright;
    }
    &:active {
      background-color: $coffe;
    }
    &:focus-visible {
      background-color: $coffe-bright;
    }
    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $white;
      cursor: progress;
      background-color: $coffe-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--redly {
    background-color: $redly;
    color: $gray-middle-dark;
    &:hover {
      background-color: $redly-bright;
    }
    &:active {
      background-color: $redly;
    }
    &:focus-visible {
      background-color: $redly-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $black-light;
      cursor: progress;
      background-color: $white-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  &--purple {
    background-color: $purple;
    color: $white;
    &:hover {
      background-color: $purple-bright;
    }
    &:active {
      background-color: $purple;
    }
    &:focus-visible {
      background-color: $purple-bright;
    }

    &--disable {
      color: $white;
      background-color: $disable-color;
      cursor: not-allowed;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }

    &--progress {
      color: $black-light;
      cursor: progress;
      background-color: $purple-bright;
      &:focus-visible {
        outline: none !important;
        box-shadow: none;
      }
    }
  }
}

/* Card */
.card {
  padding: 42px 26px;
  border-radius: 9px;

  &--white {
    background-color: $white;
    color: $black;
  }

  &--white-pure {
    background-color: $white-pure;
    color: $black;
  }

  &--primary-color {
    background-color: $primary-color;
    color: $primary-text-color;
  }

  &--secondary-color {
    background-color: $secondary-color;
    color: $secondary-text-color;
  }

  &--tertiary-color {
    background-color: $tertiary-color;
    color: $tertiary-text-color;
  }

  &--non-padding {
    padding: 0px;
  }

  &--non-borders {
    border-radius: 0px;
  }
  &--non-shadow {
    box-shadow: none;
  }

  &--with-padding {
    padding: 42px 26px;
  }

  &--with-borders {
    border-radius: 9px;
  }
  &--with-shadow {
    box-shadow: -3px 5px 15px 4px #383636, -9px 12px 2em 0em #29292a;
  }
}

.circle {
  border: none;
  padding: 7px;
  background-color: $tertiary-bg-color;
  border-radius: 50%;
}

/* Multiselection Input */
.mtin {
  width: 100%;
  margin: 15px 0px;

  &-title {
    font-size: 14px;
    font-weight: 400;
  }

  &-content {
    position: relative;
    height: auto;
    //max-height: 73px;
  }

  &-combobox {
    position: relative;
    //width: fit-content;
    display: grid;
    grid-template-columns: 1fr 23%;

    .bttn {
      margin: 6px 0px;
    }

    .loader {
      p {
        left: 68%;
        top: 26%;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &-inpt {
    @extend .inpt;
    margin: 6px 0px 0px 0px;
    //swidth: 70% !important;
  }

  &-control {
    height: fit-content;
    position: relative;
    //width: fit-content;
    display: grid;
    grid-template-columns: auto 42px 42px 42px;
    .chk {
      height: fit-content;
      margin: auto 5px;
    }

    .control-img {
      height: 12px;
    }

    .bttn {
      margin: 0px;
      width: none;
      height: fit-content;
    }
  }

  &-list {
    background-color: $tertiary-bg-color;
    max-height: 98px;
    position: relative;
    z-index: 1;
    bottom: -2px;
    border: solid 1px $primary-color;
    border-radius: 4px;
    //height: 30vh;
    overflow-y: scroll;
    padding: 4px;
    &--hide {
      //visibility: hidden;
      display: none;
    }
    &--show {
      display: block;
      //visibility: visible;
    }
    &--empty {
      margin: 0;
    }
  }

  &-badgelist {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    top: 4px;
    position: relative;
    margin: 0px 0px 14px 0px;

    .badge {
      margin: 2px 2px;
      padding: 2px 5px;
      background-color: $primary-color;
      color: $secondary-text-color;
      text-align: center;
      font-size: 11px;
      border-radius: 6px;
      height: fit-content;
      &:hover {
        background-color: $primary-color-bright;
        cursor: pointer;
      }
    }

    &--prev {
      height: auto;
      max-height: 50px;
      overflow-y: scroll;
    }

    &--full {
      height: auto;
    }

    &--close {
      display: none;
    }
  }
}

input:not([type="radio"]),
select,
textarea {
  @extend .inpt;
  width: 96%;
  max-width: 96%;
  min-width: 96%;
  border-radius: 5px;
  border: none;
  padding: 8px 12px;
  margin: 6px 0px;
  box-sizing: border-box;
  color: $tertiary-text-color;
  height: 42px;
  min-height: 42px;
  max-height: 213px;
  font-size: 13px;
  background-color: $accent-bg-color;

  &:focus {
    outline: none !important;
    box-shadow: 0 0 2px $primary-color;
  }
  option {
    //margin: 40px;
    //background-color: $red;
    //color: #fff;
    //text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
}

input[type="radio"],
input[type="checkbox"] {
  &:checked {
    accent-color: $primary-color;
  }
  &:after {
    box-shadow: none;
  }
}

.chk {
  margin: auto 0px;
  min-height: auto;
  display: flex;
  justify-items: center;

  &:hover {
    //cursor: pointer;
  }

  label {
    display: grid;
    grid-template-columns: 14px 1fr;
    min-height: auto;
    cursor: pointer;

    input {
      margin: auto;
      min-height: 20px;
      height: fit-content;
      cursor: pointer;

      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:focus-visible {
        outline: none;
      }
    }

    span {
      margin: auto 10px;
      min-height: auto;
    }
  }
}

/* Modal */
.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  &-content {
    position: relative;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0px 26px;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &-info {
    position: relative;
    top: -28px;
  }

  &-title {
    font-size: 22px;
    font-weight: 700;
    margin: 6px 0px 9px 0px;
    color: $tertiary-text-color;
  }

  &-description {
    font-size: 17px;
    margin: 18px 0px 0px 0px;
    color: $accent-text-color;
  }

  &-icon {
    margin: auto;
    height: 70px;
    width: 70px;
  }

  &-image {
    @extend .circle;
    height: 70px;
    width: 70px;
    color: $accent-text-color;
    &--hide {
      display: none;
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
    }
  }

  &-background {
    height: 90%;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-picture {
    height: 100%;
    width: auto;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      height: auto;
      width: 100%;
      max-width: 70%;
    }
  }

  &-btn {
    margin: 8px 2px 2px 2px;
    @extend .bttn;

    &--neutro {
      @extend .bttn--secondary-color;
    }

    &--positive {
      @extend .bttn--primary-color;
    }

    &--negative {
      @extend .bttn--white;
    }
  }

  &--open {
    display: block;
  }
  &--close {
    display: none;
  }
}

/* Linea decorativa */
.line {
  border-right: 2px solid #707070;
  padding: 6px 0px;
  height: 2rem;
}

/* Contenedor generico */
.container {
  background-color: $primary-bg-color;
}

/* Contenedor pantalla principal */
.container-body {
  width: 90%;
  background-color: $primary-bg-color;
  margin: 1.2em auto;
  min-height: 100vh;

  @include media-breakpoint-up(md) {
    width: 70%;
    margin: 1em auto;
  }

  .container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2em 0px;
    color: $primary-text-color;

    &--margin-bottom {
      margin: 0px 1px 2em 0px;
    }

    .title-left {
      position: relative;
      text-align: left;
      margin: auto 0px;
      z-index: 1;
      font-size: 21px;
      font-weight: 500;
      color: $tertiary-text-color;
    }
    .btn-right {
      width: fit-content;
      z-index: 1;
    }
  }

  .container-filter {
    border-radius: 9px;
    background-color: $secondary-color;
    color: $secondary-text-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto auto 22px auto;
    height: 35px;

    @include media-breakpoint-down(md) {
      border-radius: 0px;
      position: fixed;
      width: 100%;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0px;
      z-index: 1;
      justify-content: flex-start;
    }

    .filter-combo {
      background-color: $secondary-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-top-left-radius: 0px;

      @include media-breakpoint-up(md) {
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        flex-grow: 2;
      }

      .filter-icon {
        //padding: 0px 12px;
      }

      .filter-text {
        margin: 0px 8px;
        font-size: 12px;
      }
    }

    .filter-inpt {
      border: none;
      text-align: end;
      background-color: $tertiary-bg-color;
      height: auto;
      margin: 0px;
      width: 100%;

      &:focus {
        outline: none !important;
        box-shadow: none;
      }

      &::placeholder {
        color: $neutro-color;
        text-align: end;
      }

      @include media-breakpoint-up(md) {
        padding: 0px 12px;
        flex-grow: 16;
        text-align: start;
        &::placeholder {
          text-align: start;
        }
      }
    }

    .filter-search {
      background-color: $tertiary-bg-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-grow: 2;
      padding: 13px;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
      }
    }

    input:not([type="radio"]) {
      min-width: 10%;
      border-radius: 0px;
    }
  }
}

.loader {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

  p {
    position: relative;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.arrow {
  text-align: center;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &-img {
    text-align: center;
    &--open {
      transform: rotate(180deg);
      transition-duration: 0.5s;
    }
    &--close {
      transform: rotate(0deg);
      transition-duration: 0.5s;
    }
  }
}
