.content-me {
  height: 70vh;
}
.me {
  @extend .card;
  @extend .card--white;

  box-shadow: none;
  //position: relative;
  //margin: 4em auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 14px;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
  position: relative;

  .name {
    font-weight: bold;
    text-align: right;
  }
}
