.container-login {
  height: 100vh;

  .panel {
    &-back {
      position: relative;
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 100vh;
        top: 0;
        left: none;
        bottom: 0;
        right: 0;
        width: 65vw;
        height: 100vh;
      }

      .bg-image {
        width: 100vw;
        @include media-breakpoint-up(md) {
          //height: 100vh;
        }
      }

      .decor-image {
        width: 100vw;
        position: absolute;
        bottom: -20%;
        left: 0;
        right: 0;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      .logo-image {
        position: absolute;
        width: 70%;
        top: 20%;
        left: 15%;
        right: 30%;
        z-index: 10;
        @include media-breakpoint-up(md) {
          width: 70%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &-front {
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 50vh;
      position: relative;

      @include media-breakpoint-down(sm) {
        left: 0;
      }

      @include media-breakpoint-up(sm) {
        top: 0;
        bottom: 0;
        right: 0;
        width: 35vw;
        height: 100vh;
        position: absolute;
      }

      .shape {
        @extend .card;

        input {
          @extend .inpt;
          margin: 0px 0px 12px 0px;
        }

        &-top {
          @extend .card--white-pure;
          top: 50%;
          left: 50%;
          width: 70%;
          z-index: 700;
          position: absolute;
          transform: translate(-50%, -90%);

          @include media-breakpoint-up(sm) {
            width: 120%;
            transform: translate(-176%, -24%);
          }

          @include media-breakpoint-up(md) {
            width: 80%;
            transform: translate(-92%, -74%);
          }

          @include media-breakpoint-up(lg) {
            width: 95%;
            transform: translate(-76%, -78%);
          }

          a {
            font-size: 12px;
          }
        }

        &-bottom {
          @extend .card--secondary-color;
          top: 50%;
          left: 50%;
          width: 85%;
          z-index: 10;
          position: absolute;
          text-align: center;
          padding: 56px 26px 16px 26px;
          transform: translate(-50%, -5%);

          @include media-breakpoint-up(sm) {
            width: 107%;
            padding: 16px 16px 16px 40px;
            transform: translate(-92%, 50%);
          }

          @include media-breakpoint-up(md) {
            width: 80%;
            padding: 16px;
            transform: translate(-92%, 74%);
          }

          @include media-breakpoint-up(lg) {
            width: 95%;
            transform: translate(-76%, 78%);
          }

          label {
            font-size: 12px;
          }
        }

        &-center {
          @extend .card--white-pure;
          top: 50%;
          left: 50%;
          width: 70%;
          z-index: 700;
          position: absolute;
          transform: translate(-50%, -90%);

          @include media-breakpoint-up(sm) {
            width: 120%;
            transform: translate(-126%, -32%);
          }

          @include media-breakpoint-up(md) {
            width: 100%;
            transform: translate(-168%, -22%);
          }

          @include media-breakpoint-up(lg) {
            width: 70%;
            transform: translate(-95%, -50%);
          }

          a {
            font-size: 12px;
          }
        }
      }
    }
  }
}
