.panel {
  margin: 0px auto;
  font-size: 12px;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 2fr;
  line-height: 1.8;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 4fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 4fr;
  }

  &-option {
    padding: 2px 0px;
    margin: 0;
    &:hover {
      color: $tertiary-color;
      font-weight: 300;
      cursor: pointer;
    }
  }

  &-side {
    @extend .card;
    @extend .card--white;
    box-shadow: none;
    text-align: right;
    height: 75vh;
    overflow-y: scroll;
    padding: 22px 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &-main {
    @extend .card;
    @extend .card--white;
    box-shadow: none;
    height: 75vh;
    overflow-y: scroll;
    padding: 4px 10px;

    &--empty {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 100%;

      p {
        text-align: center;
      }
    }
  }

  .option-selected {
    color: $tertiary-color-bright;
    font-weight: 300;
  }
}

.table {
  width: 100%;
  border-spacing: 0px;

  &-header {
    text-align: center;
  }

  &-register {
    text-align: center;
    height: 32px;

    &:hover {
      cursor: pointer;
      background-color: $secondary-color;
      color: $white;
    }
  }
}
